.hidden-scrollbar {
  scrollbar-width: none;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none
}

.react-player__preview {
  border-radius: 0.5rem;
}