@import "cropper.css";
@import "attachment-gallery.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-3xl font-bold text-darkblue;
    }

    h2 {
        @apply text-2xl lg:text-3xl font-semibold text-darkblue;
    }

    h3 {
        @apply text-xl lg:text-2xl font-semibold text-darkblue;
    }

    .primary-link {
        @apply text-blue hover:text-gray focus-visible:text-gray outline-none transition-colors ease-linear duration-200
    }

    .main-transition-colors {
        @apply transition-colors ease-linear duration-200
    }

    .community-post-title {
        @apply text-xl font-semibold text-darkblue leading-none lg:leading-tight;
    }

    @font-face {
        font-family: 'Inter';
        src: url("/fonts/Inter-Regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Inter';
        src: url("/fonts/Inter-Medium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Inter';
        src: url("/fonts/Inter-SemiBold.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Inter';
        src: url("/fonts/Inter-Bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'AvertaPE';
        src: url("/fonts/avertaPE/349C84_5_0.eot");
        src: url("/fonts/avertaPE/349C84_5_0.eot?#iefix") format("embedded-opentype"), url("/fonts/avertaPE/349C84_5_0.woff2") format("woff2"), url("/fonts/avertaPE/349C84_5_0.woff") format("woff"), url("/fonts/avertaPE/349C84_5_0.ttf") format("truetype"), url("/fonts/avertaPE/349C84_5_0.svg#wf") format("svg");
        font-style: normal;
        font-weight: 100;
    }
    @font-face {
        font-family: 'AvertaPE';
        src: url("/fonts/avertaPE/349C84_1_0.eot");
        src: url("/fonts/avertaPE/349C84_1_0.eot?#iefix") format("embedded-opentype"), url("/fonts/avertaPE/349C84_1_0.woff2") format("woff2"), url("/fonts/avertaPE/349C84_1_0.woff") format("woff"), url("/fonts/avertaPE/349C84_1_0.ttf") format("truetype"), url("/fonts/avertaPE/349C84_1_0.svg#wf") format("svg");
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: 'AvertaPE-RegularItalic';
        src: url("/fonts/avertaPE/349C84_2_0.eot");
        src: url("/fonts/avertaPE/349C84_2_0.eot?#iefix") format("embedded-opentype"), url("/fonts/avertaPE/349C84_2_0.woff2") format("woff2"), url("/fonts/avertaPE/349C84_2_0.woff") format("woff"), url("/fonts/avertaPE/349C84_2_0.ttf") format("truetype"), url("/fonts/avertaPE/349C84_2_0.svg#wf") format("svg");
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: 'AvertaPE';
        src: url("/fonts/avertaPE/349C84_0_0.eot");
        src: url("/fonts/avertaPE/349C84_0_0.eot?#iefix") format("embedded-opentype"), url("/fonts/avertaPE/349C84_0_0.woff2") format("woff2"), url("/fonts/avertaPE/349C84_0_0.woff") format("woff"), url("/fonts/avertaPE/349C84_0_0.ttf") format("truetype"), url("/fonts/avertaPE/349C84_0_0.svg#wf") format("svg");
        font-style: normal;
        font-weight: 600;
    }
    @font-face {
        font-family: 'AvertaPE';
        src: url("/fonts/avertaPE/349C84_3_0.eot");
        src: url("/fonts/avertaPE/349C84_3_0.eot?#iefix") format("embedded-opentype"), url("/fonts/avertaPE/349C84_3_0.woff2") format("woff2"), url("/fonts/avertaPE/349C84_3_0.woff") format("woff"), url("/fonts/avertaPE/349C84_3_0.ttf") format("truetype"), url("/fonts/avertaPE/349C84_3_0.svg#wf") format("svg");
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'Mulish';
        src: url("/fonts/Mulish-Bold.woff2") format("woff2");
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'Mulish';
        src: url("/fonts/Mulish-Regular.woff2") format("woff2");
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'Ubuntu';
        src: url("/fonts/Ubuntu-Bold.woff2") format("woff2");
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'Ubuntu';
        src: url("/fonts/Ubuntu-Medium.woff2") format("woff2");
        font-style: normal;
        font-weight: 500;
    }
}

@layer components {

    /* -------------------------------hide scrollbar---------------------------------------- */
    /* source: https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll */
    .hide-scrollbar {
        -ms-overflow-style: none; /* Internet Explorer and Edge */
        scrollbar-width: none; /* Firefox */
    }

    /* Hide the scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .word-break-word {
        word-break: break-word;
    }

    /* ------------------------------------------------------------------------------------- */
    .customScroll::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    .customScroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .customScroll::-webkit-scrollbar-thumb {
        background-color: rgba(204, 213, 225, 1);
        border-radius: 10px;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    line-height: 1.5;
    height: 100%;
}

body {
    background: #F1F4F8;
}

#__next {
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

/*.content {*/
/*  display: grid;*/
/*  grid-template-columns: auto;*/
/*  grid-template-areas: "sidebar main";*/
/*  grid-column-gap: 64px;*/
/*}*/

header {
    position: sticky;
    top: 0;
    height: 69px;
    width: 100%;
}

aside {
    grid-area: sidebar;
}

main {
    grid-area: main;
}

.preview-image-wrapper {
    img {
        aspect-ratio: 1 / 1;
        width: fit-content;
        object-fit: cover;
    }
}

@layer utilities {
    @keyframes onAutoFill {
      from {}
      to {}
    }
  
    input:-webkit-autofill {
      animation-name: onAutoFill;
    }
  }